import { numberToChinese } from 'inksmallfrog-chinese-number'

const canvas = document.getElementById('canvas') as HTMLCanvasElement
const background = document.getElementById('background') as HTMLImageElement

function getCurrentTime () {
  const time = new Date()
  let h = numberToChinese(time.getHours())
  if (time.getHours() === 2) {
    h = '两'
  }
  let m = numberToChinese(time.getMinutes())
  if (time.getMinutes() === 0) {
    m = ''
  } else if (time.getMinutes() < 10) {
    m = `零${m}`
  } else if (time.getMinutes() === 10) {
    m = `${m}分`
  }
  return `${h}点${m}了！`
}

function main () {

  const context = canvas.getContext('2d')
  context.drawImage(background, 0, 0)

  let savedTime = ''

  function drawCurrentTime() {
    context.fillStyle = '#fff'
    context.fillRect(480, 15, 80, 350)

    const currentTime = getCurrentTime()
    if (savedTime == currentTime) return

    const timeText = currentTime.split('')
    let paddingTop = (60 + (350 - (timeText.length * 40))) / 2
    for (let i = 0; i < timeText.length; ++i) {
      const t = timeText[i]
      context.fillStyle = '#000'
      context.font = '32px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei,  sans-serif'
      context.fillText(t, 500, paddingTop + (40 * i), 32)
    }
  }
  setInterval(drawCurrentTime, 1000)
  drawCurrentTime()
}

background.onload = main